<template>
  <div class="notice">
<!--    <div class="date">
      2022年7月25日
    </div>-->
    <div v-for="(item,index) in noticeList" :key="index">
      <div class="card">
        <div class="top">
          <div class="name">{{ item.title }}</div>
          <div class="time">{{item.updateTime?item.updateTime:item.createTime}}</div>
        </div>
        <div class="content" v-html="item.broadmemo"></div>
      </div>
    </div>
<!--    <div class="card">
      <div class="top">
        <div class="name">关于系统升级的公告</div>
        <div class="time">14:00</div>
      </div>
      <div class="content">为了更好地为您提供服务，我们将于10月26日0：00-5：00进行系统升级，给您带来的不便，敬请谅解。</div>
    </div>
    <div class="card">
      <div class="top">
        <div class="name">关于系统升级的公告</div>
        <div class="time">14:00</div>
      </div>
      <div class="content">为了更好地为您提供服务，我们将于10月26日0：00-5：00进行系统升级，给您带来的不便，敬请谅解。</div>
    </div>

    <div class="date">
      2022年7月24日
    </div>
    <div class="card">
      <div class="top">
        <div class="name">关于系统升级的公告</div>
        <div class="time">14:00</div>
      </div>
      <div class="content">为了更好地为您提供服务，我们将于10月26日0：00-5：00进行系统升级，给您带来的不便，敬请谅解。</div>
    </div>
    <div class="card">
      <div class="top">
        <div class="name">关于系统升级的公告</div>
        <div class="time">14:00</div>
      </div>
      <div class="content">为了更好地为您提供服务，我们将于10月26日0：00-5：00进行系统升级，给您带来的不便，敬请谅解。</div>
    </div>-->
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: 'Notice',
  data() {
    return {
      noticeList: [],
      schooId: '',
    }
  },
  created() {
    this.schooId = this.$route.query.schoolId;
    this.queryNoticeList();
  },
  methods: {
    queryNoticeList() {
      this.$http.get(this.baseMallUrl + `/h5_mall/queryNoticeList/${Vue.prototype.deptId}/${this.schooId}`).then((res) => {
        this.noticeList = res.data;
      })
    }
  }
};
</script>


<style lang="less" scoped>
.notice {
  padding: 9px 10px;
  min-height: 100vh;
  background-color: #F4F5F6;

  .date {
    padding: 5px 0 12px;
    line-height: 1;
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
  }

  .card {
    background: #FFFFFF;
    border-radius: 5px;
    margin-bottom: 9px;
    padding: 17px 10px 17px 12px;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;

      .name {
        font-size: 12px;
        font-weight: 500;
        color: #212121;
        line-height: 12px;
      }

      .time {
        font-size: 12px;
        color: #999;
        line-height: 12px;
      }
    }

    .content {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
      line-height: 18px;
    }
  }
}
</style>
